*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}


.loadingbar {
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: #242f3f;
}



.loader {
    display: inline-block;
    width: 30px;
    height: 30px;
    position: relative;
    margin-top: 400px;
    left: 50%;
    border: 4px solid #Fff;
    animation: loader 2s infinite ease;
}

.loader-inner {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    background-color: #fff;
    animation: loader-inner 2s infinite ease-in;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(180deg);
    }

    50% {
        transform: rotate(180deg);
    }

    75% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes loader-inner {
    0% {
        height: 0%;
    }

    25% {
        height: 0%;
    }

    50% {
        height: 100%;
    }

    75% {
        height: 100%;
    }

    100% {
        height: 0%;
    }
}

.point {
    position: absolute;
    top: 85%;
    left: 31%;
    /*pointer-events: none; */
}

    .point .label {
        position: absolute;
        top: -20px;
        left: -20px;
        width: 800px;
        height: 70px;
        border-radius: 6px;
        color: #ffffff;
        font-family: Helvetica, Arial, sans-serif;
        text-align: center;
        line-height: 40px;
        font-weight: 100;
        font-size: 16px;
        cursor: help;
        transform: scale(0.001, 0.001);
        transition: transform 0.3s;
    }

    img {
        display: block;
        max-width: 100%;
        max-height: 100%;
    }


    .copy-text input.text{
        padding: 5px;
        font-size: 24px;
        color: rgb(255, 255, 255);
        border-radius:10px;
        width: 700px;
        border: none;
        outline: none;
        background: #00000077;
        border: 1px solid #ffffff77;
    }

    .copy-text button{
        padding:10px;
        color:rgb(255, 255, 255);
        font-size:24px;
        outline:none;
        background-color: rgba(122, 122, 122, 0.183);
        border-radius: 20px;
        cursor:pointer;
        border:2px solid rgba(255,255,255,0);
        box-shadow:0px 0px 20px 8px #ffffff00;
    }

    .copy-text button:hover{
        font-size:24px;
        padding:10px;
        border-radius: 20px;
        color:#00ffea;
        background-color: black;
        box-shadow: 0px 0px 20px 4px #00eeffa2;
        border: 2px solid #00eeff;
        transition: all 0.5s;
    }

    .copy-text button:active {
        background: #ffffff;
        color: #00ff88;
    }

    .copy-text button:before{
        content:"Copied";
        position:absolute;
        top:-45px;
        right: 10px;
        background:#3380c98a;
        color: white;
        padding:8px 8px;
        border-radius:20px;
        font-size:15px;
        display:none;
    }

    .copy-text button:after{
        content:"";
        position:absolute;
        top:-20px;
        right:25px;
        width:10px;
        height:5px;
        display:flex;
    }

    .copy-text.active button:before,
    .copy-text.active button:after{
        display:block;
    }


    .point.visible .label {
        transform: scale(1, 1);
    }


    
